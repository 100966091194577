/* body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent;
} */
@font-face {
  font-family: 'silver_south_scriptregular';
  src: local('FiraSans'), url(./assets/fonts/SilverSouthScript.ttf) format('truetype');
}
@font-face {
  font-family: 'im_fell_french_canonregular';
  src: local('FiraSans'), url(./assets/fonts/IMFellRegular.ttf) format('truetype');
}
@font-face {
  font-family: 'aileronthin';
  src: local('FiraSans'), url(./assets/fonts/Aileron/Aileron-Thin.ttf) format('truetype');
}
@font-face {
  font-family: 'im_fell_french_canonregular';
  src: local('FiraSans'), url(./assets/fonts/IMFellRegular.ttf) format('truetype');
}

@font-face {
    font-family: 'ralyne_demoregular';
     src: local('FiraSans'), url(./assets/fonts/Ralyne.ttf) format('truetype');
}

@font-face {
    font-family: 'ralyne_demoregular';
    src: url('./assets/fonts/ralyne-webfont.woff2') format('woff2'),
         url('./assets/fonts/ralyne-webfont.woff') format('woff');
    /* src: local('FiraSans'), url(./assets/fonts/ralyne-webfont.woff2) format('woff2'),
          url(./assets/fonts/ralyne-webfont.woff) format('woff'); */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'aileronthin';
    src: url('./assets/fonts/aileron-thin-webfont.woff2') format('woff2'),
         url('./assets/fonts/aileron-thin-webfont.woff') format('woff');
    /* src: local('FiraSans'), url(./assets/fonts/aileron-thin-webfont.woff2) format('woff2'),
          url(./assets/fonts/aileron-thin-webfont.woff) format('woff'); */
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'im_fell_french_canonregular';
    src: url('./assets/fonts/imfellregular-webfont.woff2') format('woff2'),
         url('./assets/fonts/imfellregular-webfont.woff') format('woff');
    /* src: local('FiraSans'), url(./assets/fonts/mfellregular-webfont.woff2) format('woff2'); */
          /* url(./assets/fonts/mfellregular-webfont.woff) format('woff'); */
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'silver_south_scriptregular';
    src: url('./assets/fonts/silversouthscript-webfont.woff2') format('woff2'),
         url('./assets/fonts/silversouthscript-webfont.woff') format('woff');
    /* src: local('FiraSans'), url(./assets/fonts/silversouthscript-webfont.woff2) format('woff2'),
          url(./assets/fonts/silversouthscript-webfont.woff) format('woff'); */
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'evolve_sans_regular';
  src: url('./assets/fonts/evolve_sans/EvolveSansRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'evolve_sans_bold';
  src: url('./assets/fonts/evolve_sans/EvolveSansBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nexa_regular';
  src: url("./assets/fonts/nexa/nexa_regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nexa_bold';
  src: url("./assets/fonts/nexa/nexa_bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'nexa_light';
  src: url("./assets/fonts/nexa/nexa_light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url("./assets/fonts/avenir_next_lt_pro/AvenirNextLTPro-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next LT Pro Bold';
  src: url("./assets/fonts/avenir_next_lt_pro/AvenirNextLTPro-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ant-breadcrumb a{
  color: rgba(0, 0, 0, 0.65) !important;
  font-variant: tabular-nums  !important;
  line-height: 1.5  !important;
  list-style: none  !important;
  font-feature-settings: 'tnum', "tnum"  !important;
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 14px !important;
}