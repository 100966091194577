.unsubscription
{
    min-height: 100vh;
    >div    
    {
        >div
        {
        padding-top: 10%;
        a
        {
            cursor: pointer !important;
        }
        }
    }
    h2
    {
        margin-top: 30px;
        font-weight: 100;
    }
    .social-links
    {
        margin-top: 30px;
        a
        {
            color: #3E5569;
            margin-left: 10px;
            font-size: 17px;
            cursor: pointer;
        }
    }
    
}
