
.depositsHeading{
  font-family: 'nexa_light';
  font-size: x-large;
  font-weight: bold;
  color: #01253a;
  text-align: center;
  margin-bottom: 30px;
  font-size: 1.75rem;
}
.container{
  background-color: #F2F2F2;
  width: 100%;
  height: 100%;
}
h{
  font-family:'nexa_light';
  font-size: 1.75rem;
  color: #01253a;
  font-weight: bold;
  margin-bottom: 30px;
}