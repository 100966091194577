.unsubscription
{
    padding-top: 50px;
    h1
{
    font-weight: 100;
}
    .list-preferences
    {
        input[type='checkbox']
        {
            transform: scale(1.4);
            margin-right: 7px;
        }
    }
    .btn-unsubscribe
    {
        border:none;
        margin-top: 20px;
        border-radius: 30px;
        background-color: #3E5569;
        color: white;
        font-size: 13px;
        &:hover
        {
            background-color: #3E5569;
            color: whitesmoke;
        }
    }
}

.modal-subscription-confirmation
{
    .btn-custom
    {
        border:none;
        margin-top: 20px;
        border-radius: 30px;
        color: white;
        font-size: 13px;
    }
    .btn-done
    {
        @extend .btn-custom;
        background-color: #3E5569;
        &:hover
        {
            background-color: #3E5569;
            color: whitesmoke;
        }
    }
    .btn-close
    {
        @extend .btn-custom;
        margin-left: 5px;
        background-color: grey;
        &:hover
        {
            background-color: grey;
            color: whitesmoke;
        }
    }
}